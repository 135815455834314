<template>
  <div>
    <div v-if="isMobileWidth === 1" class="d-flex justify-space-between">
      <p class="font-weight-bold title black--text">Untukmu dari VDC</p>
    </div>
    <div class="row">
        <v-col cols="6" class="text-center pr-2">
            <v-card @click="goToPackage()" class="round" color="primary" height="100">
                <v-card-text class="white--text font-weight-bold pt-4 text-12">
                    <i class="fas fa-hand-holding-heart text-30 pb-2"></i><br>
                    Paket Influencer Spesial
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6" class="text-center pl-2">
            <v-card @click="goToRegister()" color="primary" class="round" height="100" >
                <v-card-text class="white--text font-weight-bold pt-4 text-12">
                    <i class="fas fa-users text-30 pb-2"></i><br>
                    Gabung Menjadi Talent
                </v-card-text>
            </v-card>
        </v-col>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    goToRegister(){
      window.open('https://vicedigitalcreative.com/for_influencers');
    },
    goToPackage(){
      this.$router.push({name:'package-all'}); 
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.height = 150
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.height = 150
      }else {
        this.height = 300
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
